//React Module
import { useEffect, useState } from "react";
//Project Data
import ProjectData from "../../Database/Project.json";
//Swiper Module
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
//Stylesheet
import "./imageslider.css";
//Material UI
import { Box } from "@mui/material";

let useGetImages = function (prj_id) {
  return ProjectData.find((item) => item.prj_id === prj_id)?.prj_images;
};

export default function ImageSlider({ prj_id, children }) {
  const images = useGetImages(prj_id);
  const [winWidth, setWinWidth] = useState(0);
  const [winHeight, setWinHeight] = useState(0);
  const [swiperModules, setSwiperModules] = useState([
    Keyboard,
    Navigation,
    Pagination,
  ]);
  const [swiperKey, setSwiperKey] = useState({ navigation: true });

  function winResize() {
    setWinWidth(window.innerWidth);
    setWinHeight(window.innerHeight);
    if (window.innerWidth < 900) {
      setSwiperModules([Keyboard, Pagination]);
      setSwiperKey({ navigation: false });
    } else if (window.innerWidth < 500) {
      setSwiperModules([Keyboard]);
      setSwiperKey({ navigation: false });
    } else {
      setSwiperModules([Keyboard, Navigation, Pagination]);
      setSwiperKey({ navigation: true });
    }
  }

  useEffect(() => {
    winResize();
    window.addEventListener("resize", () => {
      winResize();
    });
  }, []);

  return (
    <Box
      sx={{
        width: winWidth,
        height: winHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#004d40ad",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      {children}
      <Swiper
        spaceBetween={10}
        navigation={swiperKey.navigation}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={swiperModules}
        style={{
          maxWidth: "1280px",
          maxHeight: "720px",
        }}
      >
        {images?.map((image, index) => (
          <SwiperSlide
            key={index}
            data-project-id={prj_id}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <img
              src={`/images/prj/${prj_id}/${image}`}
              alt=''
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
